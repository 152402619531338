.vacations-information {
  display: flex;
  gap: 16px;
  @media (max-width: $lg) {
    flex-direction: column-reverse;
  }
  &-days {
    display: flex;
    gap: 19px;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    @media (max-width: $lg) {
      gap: 10px;
    }
    @media (max-width: $md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }
  &-btn {
    width: 100%;
    max-width: 340px;
    max-height: 40px;
    margin-left: auto;
  }
}

// Parts
.days-information {
  background-color: #fff;
  width: 100%;
  min-width: 150px;
  max-width: 165px;
  height: 89px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  color: $gray-dark;
  position: relative;
  @media (max-width: $sm) {
    min-width: initial;
  }
  &.active {
    background-color: $secondary;
    color: #fff;
    .days-information-title {
      color: #fff;
    }
  }
  &-container {
    display: grid;
    gap: 0.5rem;
    height: 100%;
    align-items: center;
    grid-template-rows: 1fr 1fr;
  }
  &-title {
    color: $primary;
    font: normal normal normal 39px/10px Source Sans Pro;
    margin: 0;
    @media (max-width: $md) {
      font-size: 35px;
    }
  }
  &-subtitle {
    padding: 0 20px;
    font: normal normal normal 14px Source Sans Pro;
    margin: 0;
    @media (max-width: $md) {
      padding: 0;
    }
  }
  &-icon {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: -7px;
    color: $body-color;
  }
}

.input-gap {
  gap: 22px;
}

.new-permit-radio-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}
.new-permit-radio-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}
.new-permit-radio {
  width: 20px;
  height: 20px;
}

.days-current-period {
  margin-top: 40px;
  &-icon-close {
    display: block;
    cursor: pointer;
    color: #939393;
    position: absolute;
    top: 16px;
    right: 17px;
    opacity: 0.36;
  }
}
